global.$ = global.jQuery = require('jquery');
require('./bootstrap');
require('select2');
require('jquery-mask-plugin');

$(".select-milti").select2({
    theme: "bootstrap-5",
    width:"100%"
});
$(".eventselect").select2({
    theme: "bootstrap-5",
    width:"100%",
    templateSelection: function(currency){
    if (!currency.id) { return currency.text; }
    var $currency = $('<span>' + currency.element.value + '</span>');
    return $currency;
    }
});
$(".select22").select2({
    theme: "bootstrap-5",
    width:"100%",
    language: "pt-br",
})